import React from "react"
import { graphql } from "gatsby"

import { SimpleUtility } from "@hurleymc/components"

import Layout from "../components/layout"
import SEO from "../components/seo"
import PageBuilder from "../components/page-builder"

const InsideReport = ({ data }) => {
  const { craft } = data
  const { entries } = craft
  const entry = entries[0]
  return (
    <Layout>
      <SEO title={entry.title} />
      <SimpleUtility header={entry.header} />
      {entry && entry.pageBuilder && (
        <PageBuilder
          pageBuilder={entry.pageBuilder}
          location={{ pathname: entry.uri }}
        />
      )}
    </Layout>
  )
}

export const query = graphql`
  query InsideReport($id: [Craft_QueryParameter]!) {
    craft {
      entries(site: "hurleyFoundation", id: $id) {
        __typename
        title
        ... on Craft_insideReport_insideReport_Entry {
          header
          pageBuilder {
            ...PageBuilder
          }
        }
      }
    }
  }
`

export default InsideReport
